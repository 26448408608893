import React, { useState } from 'react'
import cls from './contacts.module.scss'
import { Link } from 'react-router-dom'
import { BaseTextArea } from '@/shared/ui/TextArea/BaseTextArea'
import { TextField } from '@/shared/ui/TextField/TextField'
import { useCookies } from 'react-cookie'
import { Button } from '@/shared/ui/Button/Button'
import { classNames } from '@/shared/lib/utils/classNames'
export const Contacts = ({ id }) => {
    const [cookies, setCookie] = useCookies()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [isSent, setIsSent] = useState(false)
    const [checked, setChecked] = useState(false)
    const [fields, setFields] = useState({
        name: true,
        email: true,
        phone: true,
        message: true,
    })

    const sendSupport = () => {
        if (ValidateEmail(email)) {
            fetch(
                'https://hz.axgrid.com:8099/softner/support',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        name: name,
                        phone: phone,
                        message: message,
                    }),
                }
            ).then((r) => {
                console.log('response status', r.status)
            })
        } else {
            console.log('er')
        }
    }

    function ValidateEmail(_email) {
        const validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (_email.match(validRegex)) {
            return true
        } else {
            return false
        }
    }

    const onChangeName = (event) => {
        setName(event.target.value)
        setFields({ ...fields, name: true })
        setCookie('name', event.target.value)
    }

    const onChangeEmail = (event) => {
        setEmail(event.target.value)
        setFields({ ...fields, email: true })
        setCookie('email', event.target.value)
    }

    const onChangePhone = (event) => {
        setPhone(event.target.value.replace(/\D/g, ''))
        setFields({ ...fields, phone: true })
        setCookie(
            'phone',
            event.target.value.replace(/\D/g, '')
        )
    }

    const onChangeMessage = (event) => {
        setMessage(event.target.value)
        setFields({ ...fields, message: true })
        setCookie('message', event.target.value)
    }

    const onSubmit = () => {
        let isValidName = true
        let isValidEmail = true
        let isValidPhone = true
        let isValidMessage = true

        isValidName = !(name.trim() === '')
        isValidEmail = !(email.trim() === '')
        isValidPhone = !(phone.trim() === '')
        isValidMessage = !(message.trim() === '')

        setFields({
            name: isValidName,
            email: isValidEmail,
            phone: isValidPhone,
            message: isValidMessage,
        })

        if (
            isValidPhone ||
            isValidMessage ||
            isValidEmail ||
            isValidName
        ) {
            setIsSent(true)
            sendSupport()
            setTimeout(() => {
                setIsSent(false)
            }, 2000)
        }
    }
    return (
        <div
            id={id}
            className={cls.Contacts}
        >
            <h3>Say info@softnersolutions.com</h3>
            <div className={cls.form}>
                <div
                    id="contact-block-form"
                    className={cls.textFields}
                >
                    <TextField
                        value={name}
                        onChange={onChangeName}
                        isValid={fields.name}
                        placeholder={'Your Name'}
                    />
                    <TextField
                        value={email}
                        type="email"
                        onChange={onChangeEmail}
                        isValid={fields.email}
                        placeholder={'Email'}
                    />
                    <TextField
                        value={phone}
                        type="tel"
                        onChange={onChangePhone}
                        isValid={fields.phone}
                        placeholder={'Phone'}
                    />
                </div>
                <BaseTextArea
                    className={cls.textArea}
                    value={message}
                    onChange={onChangeMessage}
                    isValid={fields.message}
                    placeholder="Tell us more about project"
                />
            </div>
            <div className={cls.privacyPolicy}>
                <input
                    className={cls.privacyCheckBox}
                    type="checkbox"
                    id="todo"
                    name="todo"
                    value={checked}
                    onChange={(e) => setChecked(!checked)}
                />
                <label
                    className={cls.checkBoxLabel}
                    for="todo"
                ></label>

                <p>
                    By sending this form I confirm that I
                    have read and accept{' '}
                    <Link
                        className={cls.privacyLink}
                        to="/privacy"
                    >
                        <span className={cls.privacyLink}>
                            {' '}
                            Privacy Policy
                        </span>
                    </Link>
                </p>
            </div>
            <Button
                disabled={
                    !Boolean(
                        name &&
                            email &&
                            phone &&
                            message &&
                            checked
                    )
                }
                className={cls.formButton}
                onClick={() => onSubmit()}
                size="large"
                withArrow
                variant="secondary"
            >
                Contact with us
            </Button>
            <div
                className={classNames(
                    cls.successMessage,
                    [],
                    {
                        [cls.hide]: !isSent,
                    }
                )}
            >
                Your message has been sent!
            </div>
        </div>
    )
}
